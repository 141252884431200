import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

export default function Kontakt() {
  const seo = {
    title: "Kontakt: Zakład Opieki Zdrowotnej & Poradnia Medycyny Rodzinnej",
    description: "Zakład Opieki Zdrowotnej Medicus-Dukla - ul. Trakt Węgierski 16, 38-450 Dukla. Tel: 13 433 03 28 / Poradnia Medycyny Rodzinnej - ul. Szkolna 25, 38-450 Głojsce - Dom Ludowy, Tel: 695 951 159",
    url: "https://medicusdukla.pl/kontakt"
  }

  const webzona = <div>
    <p><br /><a href="/" onClick="op('WEBzona'); return false;" /></p>
    <div id="WEBzona" style={{ display: "none" }}>
      <h1>Kontakt – Centrum medyczne w Dukli</h1>
      <h3>Przychodnia Dukla – sprawdź nasze dane kontaktowe. Świadczymy usługi medyczne prywatnie oraz na NFZ</h3>
      <p>W Dukli wykonujemy RTG, USG, EKG i inne badania w tym badania laboratoryjne.</p>
      <p>Nasi lekarze:</p>
      <ul>
        <li>
          <h4><strong>Lekarz: Ginekolog, Urolog, Reumatolog, Laryngolog, Stomatolog/Dentysta, Dermatolog, Kardiolog,
            Ortopeda, Neurolog, Medycyna Sportowa – Dukla</strong></h4>
        </li>
      </ul>
    </div>
  </div>

  return (
    <Layout pageClass={"kontakt"} bg={null}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      {webzona}
      <div className="section" data-g="container">
        <div data-g="grid">
          <div data-g="4 6@md 4@lg">
            <h2 className="a-heading-medium">Niepubliczny Zakład Opieki Zdrowotnej  Medicus-Dukla</h2>

            <div className="header-overtext">
              Adres
            </div>
            <p>
              ul. Trakt Węgierski 16 <br/>
              38-450 Dukla
            </p>

            <div className="header-overtext">
              Telefon
            </div>
            <p>
              13 43 30 328 <br/>
              667 720 627 – Stomatologia <br/>
              728 994 407 – Szczepienia COVID-19
            </p>

            <div className="header-overtext">
              Zarząd
            </div>
            <p>
              Prezes lek. Renata Krajmas <br/>
              Kierownik Zakładu lek. Kazimierz Krajmas
            </p>
          </div>
          <div data-g="4  6@md offset-7@md">
            <iframe id="map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2588.3036246243423!2d21.682555!3d49.554294!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0x3e9809716015c23f!2sPrzychodnia+%22MEDICUS-DUKLA%22!5e0!3m2!1spl!2spl!4v1415991052730"
                    width="100%" height={350} frameBorder={0} style={{ border: 0 }} />
          </div>
        </div>
        <div data-g="grid" className="second-clinic">
          <div data-g="4 6@md 4@lg">
            <h2 className="a-heading-medium">Poradnia Medycyny Rodzinnej</h2>
            <div className="header-overtext">
              Adres
            </div>
            <p>
              ul. Szkolna - Dom Ludowy Głojsce
            </p>

            <div className="header-overtext">
              Telefon
            </div>
            <p>
              695 951 159
            </p>

            <div className="header-overtext">
              Godziny otwarcia
            </div>
            <p>
              Poniedziałek 8:00 - 10:00  <br/>
              Środa 10:00 - 12:00
            </p>
          </div>
          <div data-g="4  6@md offset-7@md">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2587.6226442276156!2d21.614167999999996!3d49.5671429!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473c36dc12eb7aa5%3A0xfa0a8ccb5c7dfbe1!2sSzkolna+25%2C+38-450+G%C5%82ojsce!5e0!3m2!1spl!2spl!4v1415991733290"
              width="100%" height={300} frameBorder={0} style={{ border: 0 }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
